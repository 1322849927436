@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@include mat.core();

$my-app-primary: mat.define-palette(mat.$indigo-palette);
$my-app-accent:  mat.define-palette(mat.$red-palette, 500, 900, A100);
$my-app-warn:    mat.define-palette(mat.$deep-orange-palette);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include mat.all-component-themes($my-app-theme);
