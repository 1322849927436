$max-width: 412px;

[data-notify="container"].alert-addproducto {
  position: relative;
  width: $max-width;
  height: 148px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  background-color: white;
  border-radius: 4px;
  padding: 0;
  border: none;


  [data-notify="bodypane"] {
    position: absolute;
    top: 0;
    width: 100%;
    height: 114px;
    border-radius: 4px 4px 0 0;

    [data-notify="body"] {
      position: relative;
      width: 100%;
      height: 100%;

      .textventana {
        position: absolute;
        top: 10px;
        left: 20px;
        text-align: left;
        letter-spacing: 0;
        color: #1B93DB;
        font-size: 12px;
      }

      .titulo {
        position: absolute;
        top: 40px;
        left: 80px;
        text-align: left;
        letter-spacing: 0;
        color: #000000;
        font-size: 14px;
        max-width: 250px;
        overflow:hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
      }

      .mensaje {
        position: absolute;
        top: 60px;
        left: 80px;
        text-align: left;
        letter-spacing: 0;
        color: #000000;
        opacity: 0.54;
        font-size: 14px;
        max-width: 250px;
        overflow:hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  [data-notify="footerpane"] {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 34px;
    background: #1B93DB 0 0 no-repeat padding-box;
    border-radius: 0 0 4px 4px;

    [data-notify="divfooter"] {
      position: relative;
      width: 100%;
      height: 100%;

      a {
        text-decoration: none;
        position: absolute;
        top: 3px;
        padding-right: 30px;

        & span, & i {
          vertical-align: middle;
          text-align: left;
          letter-spacing: 0;
          color: white;
          opacity: 1;
        }

        & span {
          font-size: 14px;
        }

        & i {
          margin-left: 15px;
          font-size: 18px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  & > [data-notify="icon"] {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border: 1px solid #E8E8E8;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #5cb85c;
  }

}

@media screen and (max-width: 411px){
  [data-notify="container"].alert-addproducto {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
