@import "assets/estilos/notificaciones/addproductos";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #F2F2F2 !important;
}

mat-toolbar {
  background: transparent !important;
}

.textotruncado {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ligaitem {
  text-decoration: none !important;
  color:  #124680 !important;

  &:hover {
    color: #397DFF !important;
    cursor: pointer;
  }
}

.noDecoration {
  text-decoration: none !important;

  &:hover {
    color: #397DFF !important;
    cursor: pointer;
  }
}

.color- {
  &delinea {
    color: #3BE8B0 !important;
  }

  &sobrepedido {
    color: #FBBB3A !important;
  }

  &descontinuado, &precio2, &red {
    color: #F21717 !important;
  }

  &precio1, &link {
    color: #1B93DB !important;
  }

  &gray {
    color: #acacac !important;
  }

  &darkgray {
    color: #5A5A5A !important;
  }

  &lgray {
    color: #6E6E6E !important;
  }


  &activo {
    color: #EE5959 !important;
  }

  &blueking {
    color: #184B8D !important;
  }

  &blue93 {
    color: #203F93 !important;
  }

  &white {
    color: white !important;
  }
}

.bgcolor- {
  &blue93 {
    background-color: #203F93 !important;
  }

  &blueD9 {
    background-color: #4646D9 !important;
  }

  &white {
    background-color: white !important;
  }
}

.precio1 {
  text-decoration: line-through;
}

.precio2 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  opacity: 1;
}

.onhover:hover {
  color: #397DFF !important;
  cursor: pointer;
}

.spacer {
  flex: 1 1 auto
}

*:focus {
  outline: none !important;
}

.carousel-indicators li {

  &.active {
    background-color: gray !important;
  }

  width: 11px !important;
  height: 11px !important;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.carousel-indicators {
  justify-content: center !important;
}

.modalconfirmar .mat-dialog-container {
  position: relative !important;
}

.hide {
  display: none;
}
